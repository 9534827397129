const crypto = require('crypto');

const algorithm = 'aes-256-cbc';
const IV_LENGTH = 16; // For AES, this is always 16

/**
  * Criptografa o texto para ser persistido no localStorage
  * @param {*} text 
  */
const encrypt = function (text) {
    try {
        const key = sessionStorage.getItem("userKey");
        let iv = crypto.randomBytes(IV_LENGTH);
        let cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
        let encrypted = cipher.update(text);

        encrypted = Buffer.concat([encrypted, cipher.final()]);

        return iv.toString('hex') + ':' + encrypted.toString('hex');
    } catch (err) {
        console.log(err);
    }
    return '';
}

/**
 * Descriptografa o texto retornado do localStorage
 * @param {*} text 
 */
const decrypt = function (text) {
    try {
        if (text) {
            const key = sessionStorage.getItem("userKey");
            let textParts = text.split(':');
            let iv = Buffer.from(textParts.shift(), 'hex');
            let encryptedText = Buffer.from(textParts.join(':'), 'hex');
            let decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), iv);
            let decrypted = decipher.update(encryptedText);

            decrypted = Buffer.concat([decrypted, decipher.final()]);

            return decrypted.toString();
        }
    } catch (err) {
        console.log(err);
    }
    return '';
}

module.exports = { encrypt, decrypt };